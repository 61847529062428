import { render, staticRenderFns } from "./CUserList.vue?vue&type=template&id=5e116fdb&scoped=true&"
import script from "./CUserList.vue?vue&type=script&lang=ts&"
export * from "./CUserList.vue?vue&type=script&lang=ts&"
import style0 from "./CUserList.vue?vue&type=style&index=0&id=5e116fdb&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e116fdb",
  null
  
)

export default component.exports
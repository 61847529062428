
















import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class CAvatarParts extends Vue {

  @Prop()
  public avatarParts!: string[];

  @Prop()
  public value!: string;


  onEmojiClick1(part: string) {
    this.$emit('input', part);
  }

}

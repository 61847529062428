












import { Component, Prop, Vue } from "vue-property-decorator";
import PlayerStates from "youtube-player/dist/constants/PlayerStates";
import { YouTubePlayer } from 'youtube-player/dist/types';

@Component({
  components: {  },
})
export default class KVideoPlayer extends Vue {

  @Prop({ required: true })
  readonly videoId!: string;

  get player() {
    return (this.$refs.youtube as any).player as YouTubePlayer;
  }


  public async startVideo() {
    const playerState = await this.player.getPlayerState();
    if (playerState == PlayerStates.PAUSED) {
      this.player.playVideo();
      return;
    }
    this.player.seekTo(0, true);
    this.player.playVideoAt(0);
  }

}

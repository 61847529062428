var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.noteConfigs),function(note){return _c('div',{key:note.id,class:{
      note: true,
      'note-active': note.active,
    },style:({
      top: note.y + 'px',
      left: note.x + 'px',
      width: note.width + 'px',
      height: note.height + 'px',
    })})}),_vm._l((_vm.sungPitches),function(pitch){return _c('div',{key:pitch.id,class:{
      pitch: true,
      hits: pitch.hittingNote,
    },style:({
      top: pitch.y + 'px',
      left: pitch.x + 'px',
      width: pitch.width + 'px',
      height: pitch.height + 'px',
    })})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }







import {Component, Vue} from "vue-property-decorator";
@Component({})
export default class CCard extends Vue {
}

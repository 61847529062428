


















import { User } from "@/shared/game/types";
import { UltraStarFile } from "@/shared/ultrastar-parser/types";
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class CSongListItem extends Vue {

  @Prop()
  readonly file!: UltraStarFile;

  @Prop()
  readonly selected!: boolean;

  get thumbnail() {
    const id = (this as any).$youtube.getIdFromUrl(this.file.header.youtube);
    if (id) {
      return `//img.youtube.com/vi/${id}/mqdefault.jpg`;
    }
    return null;
  }

}


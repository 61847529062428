






















import { Component, Prop, Vue } from 'vue-property-decorator';
import CButton from "@/client/components/CButton.vue";
import CTextInput from "@/client/components/CTextInput.vue";
import CCard from "@/client/components/CCard.vue";
import CCodeInput from "@/client/components/CCodeInput.vue";
import { EventMessages } from "@/shared/game/messages";
import { readError, readMe } from "@/client/store/main/getters";

@Component({
  components: { CCodeInput, CCard, CTextInput, CButton },
})
export default class Home extends Vue {

  gameID = '';

  onCreateGame() {
    this.$socket.emit(EventMessages.CreateMatch);
  }

  onJoinGame() {
    // Join match with game-id of this.gameID
  }

  get error() {
    return readError(this.$store);
  }

  get me() {
    return readMe(this.$store);
  }
}

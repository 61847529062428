




































































import { Component, Vue } from "vue-property-decorator";
import { readCurrentMatch, readError, readMe, readSongs } from "@/client/store/main/getters";
import { EventMessages } from "@/shared/game/messages";
import CCard from "@/client/components/CCard.vue";
import CTextInput from "@/client/components/CTextInput.vue";
import CAvatarSelection from "@/client/components/CAvatarSelection.vue";
import CUserListItem from '@/client/components/CUserListItem.vue';
import CUserList from '@/client/components/CUserList.vue';
import CSongListItem from '@/client/components/songs/CSongListItem.vue';
import CSongList from '@/client/components/songs/CSongList.vue';
import CButton from "@/client/components/CButton.vue";
import { dispatchGetSongs, dispatchUpdateMe } from "../store/main/actions";
import PitchAnalyser, { Pitch } from "../pitch-analyser";
import { UltraStarFile } from "@/shared/ultrastar-parser/types";


@Component({
  components: {CButton, CAvatarSelection, CTextInput, CCard, CUserListItem, CUserList, CSongList, CSongListItem }
})
export default class GameLobby extends Vue {
  analyser: PitchAnalyser | null = null;
  currentPitch: Pitch | null = null;

  async mounted() {
    const gameId = this.$route.params.gameId;
    if (!this.currentMatch) {
      this.$socket.emit(EventMessages.JoinMatch, gameId);
    }

    dispatchGetSongs(this.$store);
  }

  get songs() {
    return readSongs(this.$store) || null;
  }

  async activateMicrophone() {
    this.analyser = new PitchAnalyser({
      callback: this.onPitch,
    });
    await this.analyser?.initAnalyser();
    await this.analyser?.startAnalyser();
  }

  destroyed() {
    this.analyser?.stopAnalyser();
  }


  onPitch(pitch: Pitch) {
    this.currentPitch = pitch;
  }

  setSong(id: string | number) {
    this.$socket.emit(EventMessages.SetSong, id);
  }

  startGame() {
    this.$socket.emit(EventMessages.StartMatch, '');
  }

  get difficulty() {
    return this.currentMatch?.difficulty || 1;
  }

  set difficulty(difficulty: number) {
    this.$socket.emit(EventMessages.SetDifficulty, difficulty);
  }

  get currentSongId() {
    return this.currentMatch?.currentSong?.id || null;
  }

  get error() {
    return readError(this.$store);
  }

  get currentMatch() {
    return readCurrentMatch(this.$store);
  }

  get isLeader() {
    return this.me?.type === 'leader';
  }

  get name() {
    return this.me?.name || '';
  }

  set name(value) {
    if (!value) {
      return;
    }
    value = value.replace(/\W/g, '');

    if (this.me) {
      this.me = {...this.me, name: value || ''};
    }
  }

  get me() {
    return readMe(this.$store);
  }

  set me(value) {
    if (value) {
      dispatchUpdateMe(this.$store, value);
    }
  }
}


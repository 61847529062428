












import { User } from "@/shared/game/types";
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class CUserListItem extends Vue {

  @Prop()
  readonly user!: User;

}


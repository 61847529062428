








import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class CTextInput extends Vue {

  @Prop()
  readonly id!: string;

  @Prop()
  readonly labelText!: string;

  @Prop()
  readonly value!: string;

  onInput(e: any) {
    this.$emit('input', e.target?.value);
  }
}


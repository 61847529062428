














import {Component, Vue, Prop} from "vue-property-decorator";

@Component({})
export default class CButton extends Vue {

  @Prop({default: 'red'})
  readonly color!: string;

  @Prop({default: false})
  readonly disabled!: boolean;

}















import {Component, Vue} from "vue-property-decorator";
import CAvatarParts from "@/client/components/CAvatarParts.vue";
import { readMe } from "../store/main/getters";
import { dispatchUpdateMe } from "../store/main/actions";

@Component({
  components: {CAvatarParts}
})
export default class CAvatar extends Vue {
  heads = [
            '🙂', '🥳', '😄', '😁', '😂', '😀', '🙃', '😉', '😊',
            '😇', '🥰', '😍', '🤩', '😘', '😋', '😛', '😜', '🤗',
            '🤫', '🤔', '🤐', '🤨', '😐', '😑', '😶', '😏', '😒',
            '🙄', '😬', '🤕', '🤖', '🤢', '🤧', '🥵', '🥶', '🥴',
            '😵', '🤯', '🤠', '😴', '😎', '🤓', '🧐', '😕', '😟',
            '🙁', '😮', '😯', '😲', '😳', '😱', '😖', '😣', '😞',
            '🥱', '😤', '😠', '🤬', '💀', '💩', '🤡', '👹', '👽'
          ]


  get avatar() {
    return this.me?.avatar || '';
  }

  set avatar(value) {
    if (this.me) {
      this.me = {...this.me, avatar: value || ''};
    }
  }

  get me() {
    return readMe(this.$store);
  }

  set me(value) {
    if (value) {
      dispatchUpdateMe(this.$store, value);
    }
  }
  
}


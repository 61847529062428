import { render, staticRenderFns } from "./KLineText.vue?vue&type=template&id=bc2582ec&scoped=true&"
import script from "./KLineText.vue?vue&type=script&lang=ts&"
export * from "./KLineText.vue?vue&type=script&lang=ts&"
import style0 from "./KLineText.vue?vue&type=style&index=0&id=bc2582ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc2582ec",
  null
  
)

export default component.exports